// extracted by mini-css-extract-plugin
export var container = "LinkShareSection-module--container--2O3ZZ";
export var visible = "LinkShareSection-module--visible--_lc-Q";
export var noticeContainer = "LinkShareSection-module--noticeContainer--2pNna";
export var FadeInUp = "LinkShareSection-module--FadeInUp--2iz19";
export var buttonWrap = "LinkShareSection-module--buttonWrap--1E2TL";
export var FadeInUpMobile = "LinkShareSection-module--FadeInUpMobile--1CZA2";
export var shareContainer = "LinkShareSection-module--shareContainer--ki_So";
export var textContainer = "LinkShareSection-module--textContainer--3aJN2";
export var shareSection = "LinkShareSection-module--shareSection--2VAz7";
export var background = "LinkShareSection-module--background--3j7F-";
export var FadeIn = "LinkShareSection-module--FadeIn--Z_afx";
export var Shiny = "LinkShareSection-module--Shiny--FLD1r";
// extracted by mini-css-extract-plugin
export var container = "HistorySection-module--container--kqF4c";
export var visible = "HistorySection-module--visible--3crUH";
export var heading = "HistorySection-module--heading--aIUlu";
export var FadeInUp = "HistorySection-module--FadeInUp--OQJ16";
export var paragraph = "HistorySection-module--paragraph--3VMrb";
export var history = "HistorySection-module--history--1WsCI";
export var FadeInUpMobile = "HistorySection-module--FadeInUpMobile--3TcW4";
export var historyContainer = "HistorySection-module--historyContainer--1uPFW";
export var imageWrap = "HistorySection-module--imageWrap--1tlO7";
export var FadeIn = "HistorySection-module--FadeIn--1qIEL";
export var Shiny = "HistorySection-module--Shiny--2rRPd";
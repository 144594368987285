// extracted by mini-css-extract-plugin
export var container = "SponsorSection-module--container--2y_jE";
export var headingVisible = "SponsorSection-module--headingVisible--3m7O_";
export var heading = "SponsorSection-module--heading--3Yifm";
export var FadeInUp = "SponsorSection-module--FadeInUp--2tgW8";
export var FadeInUpMobile = "SponsorSection-module--FadeInUpMobile--1exu9";
export var slashContainer = "SponsorSection-module--slashContainer--33vss";
export var sponsorVisible = "SponsorSection-module--sponsorVisible--ZH2tf";
export var sponsorContainer = "SponsorSection-module--sponsorContainer--pGhRx";
export var sponsorRow = "SponsorSection-module--sponsorRow--2JFU8";
export var grade = "SponsorSection-module--grade--QySqi";
export var FadeIn = "SponsorSection-module--FadeIn--17GwC";
export var Shiny = "SponsorSection-module--Shiny--3h5qo";
export const YOUTUBE_CHANNEL_URL = 'https://www.youtube.com/channel/UCWEzfYIpFBIG5jh6laXC6hA';

export const FACEBOOK_FRONTEND_URL = 'https://www.facebook.com/groups/webfrontend';

export const FACEBOOK_FECONF_URL = 'https://www.facebook.com/feconf.kr';

export const SLASH_21_URL = 'https://toss.im/slash-21';

export const FECONF_EMAIL = 'feconf@googlegroups.com';


export const SPONSOR_APPLY_FORM = '#';

// export const SPONSOR_APPLY_FORM = '#';

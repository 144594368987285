// extracted by mini-css-extract-plugin
export var container = "ChannelInfoSection-module--container--3pmm2";
export var visible = "ChannelInfoSection-module--visible--5-4Rm";
export var textContainer = "ChannelInfoSection-module--textContainer--RTk1r";
export var FadeInUp = "ChannelInfoSection-module--FadeInUp--OAbnK";
export var circle = "ChannelInfoSection-module--circle--1zTEE";
export var FadeInUpMobile = "ChannelInfoSection-module--FadeInUpMobile--YYOnH";
export var wrap = "ChannelInfoSection-module--wrap--1XF0W";
export var backgroundImage = "ChannelInfoSection-module--backgroundImage--2aCcv";
export var Shiny = "ChannelInfoSection-module--Shiny--ed5Tn";
export var FadeIn = "ChannelInfoSection-module--FadeIn--24SkB";
// extracted by mini-css-extract-plugin
export var container = "HeroSection-module--container--1juf6";
export var fixed = "HeroSection-module--fixed--7SpyS";
export var headingContainer = "HeroSection-module--headingContainer--Sswje";
export var down = "HeroSection-module--down--22BoP";
export var videoWrap = "HeroSection-module--videoWrap--14-v2";
export var dimmed = "HeroSection-module--dimmed--vBbwz";
export var headingWrap = "HeroSection-module--headingWrap--3yYEE";
export var circle = "HeroSection-module--circle--1we8X";
export var scrollDown = "HeroSection-module--scrollDown--3enLI";
export var downBefore = "HeroSection-module--downBefore--3yJHv";
export var downAfter = "HeroSection-module--downAfter--1IASl";
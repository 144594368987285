// extracted by mini-css-extract-plugin
export var container = "IntroSection-module--container--1Vc_m";
export var visible = "IntroSection-module--visible--3ajGs";
export var title = "IntroSection-module--title--XYLF3";
export var FadeInUp = "IntroSection-module--FadeInUp--_i04J";
export var heading = "IntroSection-module--heading--3nF9F";
export var heading2 = "IntroSection-module--heading2--3pYuR";
export var paragraph = "IntroSection-module--paragraph--OPVYA";
export var FadeInUpMobile = "IntroSection-module--FadeInUpMobile--3k9no";
export var text = "IntroSection-module--text--2g-Vk";
export var background = "IntroSection-module--background--3WZty";
export var earth = "IntroSection-module--earth--3qChd";
export var FadeIn = "IntroSection-module--FadeIn--3t_aT";
export var Shiny = "IntroSection-module--Shiny--z-l-A";
// extracted by mini-css-extract-plugin
export var container = "SpeakerSection-module--container--1-qc7";
export var visible = "SpeakerSection-module--visible--3MoHl";
export var title = "SpeakerSection-module--title--2bfIy";
export var FadeInUp = "SpeakerSection-module--FadeInUp--3tlrd";
export var description = "SpeakerSection-module--description--1Cs4w";
export var speakerContainer = "SpeakerSection-module--speakerContainer--3xNXI";
export var FadeInUpMobile = "SpeakerSection-module--FadeInUpMobile--3lYF6";
export var speakerRow = "SpeakerSection-module--speakerRow--1ZOFs";
export var FadeIn = "SpeakerSection-module--FadeIn--SQmpl";
export var Shiny = "SpeakerSection-module--Shiny--2p76J";
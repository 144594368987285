import React from 'react';

interface LogoProps {
  color?: string
}

const Logo: React.FC<LogoProps> = ({ color = 'white' }) => {
  return (
    <svg width="56" height="34" viewBox="0 0 56 34" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M3.49878 9.57234H11.9246V15.3359H22.1526V12.2795H15.3467V9.57234H21.5391V6.62262H21.4336H15.3467V3.51764H21.7883V0.5H11.9246V6.62262H3.49878V3.55646H9.6528V0.5H0V15.3359H3.49878V9.57234Z" fill={color}/>
      <path d="M9.86368 29.7839C9.33647 30.0653 8.72299 30.2011 8.02323 30.2011C7.41933 30.2011 6.85378 30.0847 6.34573 29.8615C5.83769 29.6384 5.39675 29.3279 5.03249 28.93C4.66824 28.5322 4.38066 28.0568 4.16978 27.494C3.95889 26.9312 3.86304 26.3199 3.86304 25.6504C3.86304 24.9906 3.96848 24.389 4.16978 23.836C4.38066 23.2829 4.66824 22.8074 5.04208 22.3999C5.41592 21.9924 5.85686 21.6722 6.37449 21.449C6.89212 21.2258 7.45767 21.0997 8.08074 21.0997C8.6463 21.0997 9.20227 21.2161 9.73907 21.4393C10.2759 21.6625 10.7168 22.0021 11.0427 22.4678L13.4487 20.0324C12.8257 19.324 12.0205 18.7807 11.014 18.412C10.0171 18.0432 8.99139 17.8589 7.95613 17.8589C6.8346 17.8589 5.79935 18.0432 4.83119 18.4023C3.86304 18.771 3.0195 19.2852 2.30057 19.9644C1.58164 20.6437 1.01608 21.4587 0.613485 22.429C0.210885 23.3896 0 24.4667 0 25.6601C0 26.8245 0.2013 27.8821 0.603899 28.8427C1.0065 29.8033 1.55288 30.6378 2.26222 31.3267C2.96198 32.0156 3.79594 32.5493 4.75451 32.9277C5.71308 33.3061 6.75792 33.4905 7.88903 33.4905C9.13517 33.4905 10.2279 33.2673 11.1769 32.8307C12.1259 32.394 12.9311 31.7924 13.5829 31.0356L11.1194 28.6875C10.8127 29.1435 10.3909 29.5025 9.86368 29.7839Z" fill={color}/>
      <path d="M27.0414 19.9547C26.332 19.2852 25.4885 18.771 24.5011 18.4023C23.5234 18.0432 22.4594 17.8589 21.3091 17.8589C20.1588 17.8589 19.1044 18.0432 18.1267 18.4023C17.1489 18.771 16.315 19.2852 15.596 19.9547C14.8867 20.6243 14.3307 21.4393 13.9281 22.3999C13.5255 23.3605 13.3242 24.4278 13.3242 25.6213C13.3242 26.7954 13.5255 27.8724 13.9281 28.8427C14.3307 29.813 14.8867 30.6475 15.596 31.3364C16.3054 32.0253 17.1489 32.559 18.1267 32.9374C19.1044 33.3158 20.1588 33.5002 21.3091 33.5002C22.4594 33.5002 23.5138 33.3158 24.5011 32.9374C25.4789 32.559 26.3224 32.0253 27.0414 31.3364C27.7507 30.6475 28.3067 29.813 28.7093 28.8427C29.1119 27.8724 29.3132 26.7954 29.3132 25.6213C29.3132 24.4375 29.1119 23.3605 28.7093 22.3999C28.3067 21.4393 27.7507 20.6243 27.0414 19.9547ZM25.2105 27.4649C24.9996 28.0277 24.712 28.5128 24.3382 28.9203C23.9643 29.3279 23.5234 29.6481 23.0154 29.8712C22.5073 30.1041 21.9418 30.2206 21.3187 30.2206C20.6956 30.2206 20.1301 30.1041 19.622 29.8712C19.114 29.6384 18.673 29.3182 18.3088 28.9203C17.9445 28.5128 17.657 28.0277 17.4461 27.4649C17.2352 26.9021 17.1393 26.2811 17.1393 25.6116C17.1393 24.9712 17.2352 24.3696 17.4365 23.8068C17.6378 23.2441 17.9254 22.7686 18.2992 22.3708C18.673 21.973 19.114 21.6625 19.622 21.4296C20.1301 21.1967 20.6956 21.0803 21.3187 21.0803C21.9418 21.0803 22.5073 21.1967 23.0249 21.4296C23.5426 21.6625 23.9835 21.973 24.3478 22.3708C24.712 22.7686 24.9996 23.2441 25.2105 23.8068C25.4214 24.3696 25.5172 24.9712 25.5172 25.6116C25.5172 26.2811 25.4118 26.9021 25.2105 27.4649Z" fill={color}/>
      <path d="M40.7951 27.9402H40.7376L34.852 18.2566H30.8164V33.0925H34.2577L34.1714 23.3895H34.2289L40.1337 33.0925H44.1501V18.2566H40.7184L40.7951 27.9402Z" fill={color}/>
      <path d="M56.0005 21.313V18.2566H46.3477V33.0925H49.8464V27.3289H55.5212V24.3792H49.8464V21.313H56.0005Z" fill={color}/>
    </svg>

  );
}

export default Logo;
